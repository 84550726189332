import React from "react";

export function dateToSearchFormat(date: Date) {
  date = new Date(date);

  const y = date.getFullYear();
  const m = (date.getMonth() + 1).toString().padStart(2, "0");
  const d = date.getDate().toString().padStart(2, "0");

  return `${y}-${m}-${d}`;
}

export function calculateAverageInArray(array: number[]) {
  // Check if the array is not empty
  if (array.length === 0) return 0;

  // Sum all the elements in the array
  const sum = array.reduce((acc, number) => acc + number, 0);

  // Calculate the average
  const average = sum / array.length;

  return average;
}

/**
 * A React component that renders parts of a translated text with styling and event handlers.
 * 
 * @example
 * <Render
 *   text={t("loyalty.accept_terms")}
 *   props={{
 *     className: "font-medium text-takyon-600 hover:underline",
 *     onClick: () => window.open('https://takyon.io/terms', '_blank')
 *   }}
 * />
 */
export function Render({
  text,
  props
}: {
  text: string;
  props: React.HTMLAttributes<HTMLSpanElement>;
}): JSX.Element {
  if (!text) return React.createElement("span", null);
  
  // Split the text by {{ and }}
  const parts = text.split(/{{|}}/);
  
  // Create elements array
  const elements: React.ReactNode[] = [];
  
  // Process each part
  parts.forEach((part, index) => {
    // Even indices are regular text, odd indices are highlighted parts
    if (index % 2 === 0) {
      if (part) elements.push(part);
    } else {
      // This is a highlighted part, apply the props
      elements.push(
        React.createElement("span", { key: `highlight-${index}`, ...props }, part)
      );
    }
  });
  
  return React.createElement(React.Fragment, null, ...elements);
}
