import { useEffect, useState } from "react";
import { getCollectionsPaginatedWithBookingComData } from "../../Services/metasearch";
import iCollection from "../../models/iCollection";
import IconNew from "../components/Icon/Icon";
import { useTranslation } from "react-i18next";
import CollectionController from "../../Controllers/CollectionController";
import ScrollContainer from "../components/ScrollContainer/ScrollContainer";
import Stars from "../components/Stars/Stars";
import { Reviews } from "../../../_Web/pages/Metasearch/Results";
import { openModal } from "../../Utils/modal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import HotelsMap from "../../../_Web/pages/Metasearch/HotelsMap";
import { nextFriday, addDays, format } from "date-fns";
import { fireTagManagerEvent } from "../../Services/tagmanager";

interface iPagination<T> {
  data: T[];
  total: number;
  page: number;
  size: number;
}

export default function DiscoverHotel({ hotelIdxs }: { hotelIdxs: string[] }) {
  const [data, setData] = useState<iPagination<iCollection> | null>(null);
  const { t } = useTranslation();

  const today = new Date();
  const nextWeekendStart = nextFriday(today);
  const nextWeekendEnd = addDays(nextWeekendStart, 2); // Sunday

  useEffect(() => {
    const fetchData = async () => {
      const result = await getCollectionsPaginatedWithBookingComData({
        page: 1,
        size: 100,
        query: JSON.stringify({
          _id: { $in: hotelIdxs },
        }),
        bquery: JSON.stringify({
          arrival_date: format(nextWeekendStart, "yyyy-MM-dd"),
          departure_date: format(nextWeekendEnd, "yyyy-MM-dd"),
        }),
      });
      setData(result);
    };

    if (hotelIdxs.length > 0) fetchData();
  }, []);

  return (
    <ScrollContainer
      isLightTheme={true}
      content={
        <div className="d-flex gap-3 mx-3 mx-md-0" style={{ width: "100dwh" }}>
          {data?.data?.map((hotel) => {
            const showTopChoice =
              hotel.isFree === false || hotel.isFree === undefined;

            const dest_label = hotel.census?.location;

            const urlDefault = `https://google.com/search?q=${encodeURIComponent(
              `${hotel.name.replace(/&/g, "and")} ${dest_label}`,
            )}`;

            const locationAccess0 =
              hotel._bookingComData?.accessibilityLabel?.split("‎")[0];
            const locationAccess1 = hotel._bookingComData?.accessibilityLabel
              ?.split("‎")[1]
              ?.split("‬")[0];
            const locationAccess2 = hotel._bookingComData?.accessibilityLabel
              ?.split("‎")[2]
              ?.split("‬")[0];

            // const urlHotel =
            //   hotel.census?.website &&
            //   !hotel.census.website.startsWith("https://")
            //     ? `https://${hotel.census.website}`
            //     : hotel.census?.website ??
            //       `${urlDefault}${dest_label ? ` ${dest_label}` : ""}`;

            const urlHotel = hotel?.publicContacts?.find(
              (contact) => contact.type === "website",
            )?.value;

            let directPrice =
              hotel?._bookingComData?.property?.priceBreakdown?.grossPrice
                ?.value ?? 0;
            if (hotel?.preData?.deltaPriceBookingCom)
              directPrice =
                (hotel?._bookingComData?.property?.priceBreakdown?.grossPrice
                  ?.value ?? 0) /
                (1 + hotel.preData.deltaPriceBookingCom);

            const bookingPrice =
              hotel?._bookingComData?.property?.priceBreakdown?.grossPrice
                ?.value ?? 0;

            let hotelsPrice =
              hotel?._bookingComData?.property?.priceBreakdown?.grossPrice
                ?.value ?? 0;
            if (hotel?.preData?.deltaPriceHotelsCom)
              hotelsPrice =
                directPrice * (1 + hotel.preData.deltaPriceHotelsCom);

            const cutPrice = Math.min(bookingPrice, hotelsPrice);

            const urlBe = hotel.census?.website ?? urlDefault;

            return (
              <div
                key={hotel._id}
                className="clear-panel p-0 hotel-result d-flex flex-column white-background rounded overflow-hidden justify-content-between hover:shadow-lg transition-shadow duration-300"
                style={{
                  width: "310px",
                  minWidth: "310px",
                  minHeight: "400px",
                }} // minHeight: "461px" with button
              >
                <div>
                  <div
                    className="overflow-hidden position-relative"
                    style={{ width: "100%", height: "178px" }}
                  >
                    <div
                      className="panel-shadow cursor-pointer"
                      onClick={() => {
                        CollectionController.clickOnCollection(
                          hotel._id ?? `${hotel.name}-${hotel._id}`,
                          {
                            btn: "name_of_hotel",
                            directPrice,
                            cutPrice,
                            bookingPrice,
                            hotelsPrice,
                            bookingComId: hotel._bookingComData?.hotel_id,
                          },
                        );

                        const newTabUrl =
                          "/directsearch/redirect?fallbackUrl=" + urlHotel;

                        fireTagManagerEvent("homecard_hotel", {
                          name: hotel.name,
                          bookingComId: hotel._bookingComData?.hotel_id,
                          collectionId: hotel._id.toString() || "",
                          isFree: hotel.isFree,
                          source: "home",
                          exit_url: newTabUrl,
                        });

                        window.open(newTabUrl, "_blank");
                      }}
                    ></div>
                    <div
                      className="ovwerflow-hidden"
                      style={{ height: "178px" }}
                    >
                      <div
                        className="hotel-image"
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundImage: `url(${hotel.images[0].replace(
                            "square60",
                            "square600",
                          )})`,
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                        role="img"
                        aria-label={`${hotel.name} image`}
                      />
                    </div>
                  </div>
                  <div className="hotel-details w100 flex-d flex-column pb-1 pb-md-4">
                    <div className="d-flex flex-column">
                      {showTopChoice && (
                        <div className="w100" style={{ height: "24px" }}>
                          <OverlayTrigger
                            placement="top"
                            flip={true}
                            overlay={
                              <Tooltip>{t("ds.top_choice_popover")}</Tooltip>
                            }
                          >
                            <div
                              onClick={() => {}}
                              className="top-choice-badge black-background align-items-center cursor-pointer inline-flex d-flex d-inline-flex"
                            >
                              <IconNew
                                icon="top_partner"
                                size={16}
                                style={{ fill: "white" }}
                              />
                              <span className="fs-body-xs white-color text-nowrap m-1">
                                {t("ds.top_choice")}
                              </span>
                            </div>
                          </OverlayTrigger>
                        </div>
                      )}
                      <div className="d-flex align-items-center mt-2">
                        <p
                          onClick={() => {
                            CollectionController.clickOnCollection(
                              hotel._id ?? `${hotel.name}-${hotel._id}`,
                              {
                                btn: "name_of_hotel",
                                directPrice,
                                cutPrice,
                                bookingPrice,
                                hotelsPrice,
                                bookingComId: hotel._bookingComData?.hotel_id,
                              },
                            );

                            const newTabUrl =
                              "/directsearch/redirect?fallbackUrl=" + urlHotel;

                            window.open(newTabUrl, "_blank");
                          }}
                          className="fs-h4 medium underline cursor-pointer inline-flex mt- text-nowrap text-truncate"
                        >
                          {hotel.name}
                        </p>
                        <div
                          className="inline-flex"
                          style={{ marginLeft: "4px" }}
                        >
                          <Stars
                            stars={
                              hotel._bookingComData?.property
                                .accuratePropertyClass || 0
                            }
                            className="black-color"
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-1 mt-2">
                        <div style={{ width: "20px" }}>
                          <IconNew
                            icon="star_fill"
                            style={{ fill: "#F6A90E" }}
                          />
                        </div>
                        <div className="d-flex align-items-end gap-1">
                          <p className="fs-h4 medium review-score">
                            {hotel._bookingComData?.property.reviewScore}
                          </p>
                          <p
                            className="fs-body light review-summary text-truncate"
                            style={{ maxWidth: "210px" }}
                          >
                            {hotel._bookingComData?.property.reviewScoreWord} (
                            <span
                              onClick={() => {
                                if (hotel._bookingComData) {
                                  openModal({
                                    content: (
                                      <Reviews
                                        hotel={hotel._bookingComData}
                                        from="home"
                                      />
                                    ),
                                  });
                                }
                              }}
                              className="cursor-pointer underline"
                            >
                              {hotel._bookingComData?.property.reviewCount}{" "}
                              {t("ds.reviews")}
                            </span>
                            )
                          </p>
                        </div>
                      </div>
                      <div className="height-12"></div>
                      <div className="d-flex flex-wrap align-items-center">
                        <p className="dark-grey-color fs-body light mb-0 text-truncate">
                          {locationAccess1 || locationAccess2 ? (
                            <>
                              {locationAccess1} - {locationAccess2}{" "}
                            </>
                          ) : (
                            <>{locationAccess0} </>
                          )}{" "}
                          -
                        </p>
                        <div
                          onClick={() => {
                            openModal({
                              content: (
                                <div
                                  style={{
                                    height: "100vh",
                                    width: "100%",
                                  }}
                                >
                                  <HotelsMap
                                    hotels={
                                      hotel._bookingComData
                                        ? [hotel._bookingComData]
                                        : []
                                    }
                                    selected={String(
                                      hotel._bookingComData?.hotel_id,
                                    )}
                                    onClick={() => {}}
                                    searchLocation={() => {}}
                                  />
                                </div>
                              ),
                            });
                          }}
                          className="cursor-pointer underline ms-1"
                        >
                          {t("ds.show_map")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="px-4 py-3 border-top">
              <Button
                onClick={() => {
                  CollectionController.clickOnCollection(
                    hotel._id ??
                      `${hotel._bookingComData?.property.name}-${hotel._bookingComData?.property.id}`,
                    {
                      btn: "visit_website",
                      directPrice,
                      cutPrice,
                      bookingPrice,
                      hotelsPrice,
                      bookingComId: hotel._bookingComData?.property.id,
                    },
                  );

                  const newTabUrl = "/directsearch/redirect?url=" + urlBe;
                  window.open(newTabUrl, "_blank");
                }}
                className="w100"
                text={t("ds.visit_webiste")}
              />
            </div> */}
              </div>
            );
          })}
        </div>
      }
    ></ScrollContainer>
  );
}
