import { useMemo, useState } from "react";
import { getRandomNumberBetween } from "../../assets/Utils/generic";

import l1 from "../../assets/img/IMG_LOADING/IMG1-LOADING.jpg";
import l2 from "../../assets/img/IMG_LOADING/IMG2-LOADING.jpg";
import l3 from "../../assets/img/IMG_LOADING/IMG3-LOADING.jpg";
import l4 from "../../assets/img/IMG_LOADING/IMG4-LOADING.jpg";
import l5 from "../../assets/img/IMG_LOADING/IMG5-LOADING.jpg";
import l6 from "../../assets/img/IMG_LOADING/IMG6-LOADING.jpg";
import l7 from "../../assets/img/IMG_LOADING/IMG7-LOADING.jpg";
import l8 from "../../assets/img/IMG_LOADING/IMG8-LOADING.jpg";
import l9 from "../../assets/img/IMG_LOADING/IMG9-LOADING.jpg";
import l0 from "../../assets/img/IMG_LOADING/IMG10-LOADING.jpg";

const LOAD = [
  {
    img: l1,
    text: "l1",
  },
  {
    img: l2,
    text: "l2",
  },
  {
    img: l3,
    text: "l3",
  },
  {
    img: l4,
    text: "l4",
  },
  {
    img: l5,
    text: "l5",
  },
];

export default function useRandomLoad() {
  const [randomLoad, setRandomLoad] = useState(-1);

  const load = useMemo(
    () => (randomLoad >= 0 ? LOAD[randomLoad] : undefined),
    [randomLoad],
  );

  function openLoad() {
    setRandomLoad(getRandomNumberBetween(0, LOAD.length - 1));
  }

  function closeLoad() {
    setRandomLoad(-1);
  }

  return { load, openLoad, closeLoad };
}
