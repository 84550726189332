import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TakCard from "../TakCard/TakCard";
import { apiErrorToast } from "../../../Utils/errors";

import { iWallSlot } from "../../../../config/wall";
import NftController from "../../../Controllers/NftController";
import Skeleton from "react-loading-skeleton";
import { iNft } from "../../../models/iNft";
import Icon from "../Icon/Icon";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN, LARGE_SHORT_SCREEN } from "../../../Utils/generic";
import { useSelector } from "react-redux";
import { selectTakWidth, setTakWidth } from "../../../../redux/slices/appSlice";
import { useAppDispatch } from "../../../../redux/redux";
import ScrollContainer from "../ScrollContainer/ScrollContainer";
import { fireTagManagerEvent } from "../../../Services/tagmanager";
import { useNavigate, useLocation } from "react-router-dom";
import getLink, { LINK_TYPE } from "../../../../config/Links";

const MIN_TAK_WIDTH = 280;

interface ChevronButtonProps {
  direction: "left" | "right";
  onClick: Function;
}

function ChevronButton({ direction, onClick }: ChevronButtonProps) {
  return (
    <div
      className="height-40 width-40 d-flex align-items-center justify-content-center m-3 cursor-pointer"
      style={{
        pointerEvents: "all",
        border: "1px solid gray",
        borderRadius: "100%",
        backgroundColor: "#f5f5f5",
      }}
      onClick={() => onClick()}
    >
      <Icon className="gray-color" icon={`chevron_${direction}`} size={30} />
    </div>
  );
}

export function TakCarousel({
  nfts,
  scroll,
  isLoading,
  mobileScroll = true,
  isLightTheme = true,
}: {
  nfts: iNft[];
  scroll?: boolean;
  isLoading?: boolean;
  mobileScroll?: boolean;
  isLightTheme?: boolean;
}) {
  const takWidth = useSelector(selectTakWidth);
  const dispatch = useAppDispatch();

  const takScrollContainer = useRef<HTMLDivElement>(null);
  const [showArrows, setShowArrows] = useState(false);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const isShortScreen = useMediaQuery({
    query: "only screen and (min-width : 769px) and (max-height : 600px)",
  });

  useEffect(() => {
    const run = () => {
      const responsiveTakWidth = isShortScreen ? 250 : MIN_TAK_WIDTH;

      const w = takScrollContainer.current?.offsetWidth;
      if (!w) return;
      const howManyTakPerView = Math.floor(w / responsiveTakWidth);
      const _takWidth = w / howManyTakPerView;
      if (takWidth !== _takWidth) dispatch(setTakWidth(_takWidth));

      if (nfts?.length) setShowArrows(howManyTakPerView < nfts.length);
    };

    run();

    setInterval(() => {
      run();
    }, 150);
  }, [nfts]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!isSmallScreen && scroll && showArrows && takWidth ? (
        <div
          className="d-flex h100 justify-content-between align-items-center"
          style={{
            position: "absolute",
            zIndex: 100,
            pointerEvents: "none",
            width: "calc(100% + 125px)",
          }}
        >
          <ChevronButton
            direction="left"
            onClick={() => {
              takScrollContainer.current?.scrollBy({
                left: -takWidth,
                behavior: "smooth",
              });
            }}
          />
          <ChevronButton
            direction="right"
            onClick={() => {
              takScrollContainer.current?.scrollBy({
                left: takWidth,
                behavior: "smooth",
              });
            }}
          />
        </div>
      ) : null}

      <ScrollContainer
        isLightTheme={isLightTheme}
        content={
          <div className="d-flex gap-3 mx-3 mx-md-0">
            {isLoading ? (
              <>
                {Array(4)
                  .fill(0)
                  .map((item, key) => (
                    <div
                      style={{
                        maxWidth: isSmallScreen
                          ? mobileScroll
                            ? takWidth
                            : "100%"
                          : takWidth,
                        minWidth: isSmallScreen
                          ? mobileScroll
                            ? takWidth
                            : "100%"
                          : takWidth,
                      }}
                      key={`takcard_${key}`}
                      className="p-2"
                    >
                      <Skeleton
                        style={{
                          opacity: "0.5",
                          width: "100%",
                          aspectRatio: "8/11",
                        }}
                      />
                    </div>
                  ))}
              </>
            ) : null}
            {!isLoading ? (
              <>
                {nfts.map((item, key) => (
                  <div
                    // style={{
                    //   maxWidth: isSmallScreen
                    //     ? mobileScroll
                    //       ? takWidth
                    //       : "100%"
                    //     : takWidth,
                    //   minWidth: isSmallScreen
                    //     ? mobileScroll
                    //       ? takWidth
                    //       : "100%"
                    //     : takWidth,
                    // }}
                    style={{
                      minWidth: "310px",
                      width: "310px",
                    }}
                    key={`takcard_${key}`}
                  >
                    <TakCard nft={item} key={`slot_${key}`} />
                  </div>
                ))}
              </>
            ) : null}
          </div>
        }
      ></ScrollContainer>
    </div>
  );
}

interface Props {
  slot?: iWallSlot;
  isNew?: boolean;
  isLightTheme?: Boolean;
}

export default function TakContainer(props: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [nfts, setNfts] = useState<iNft[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const scroll = (props.slot?.config.payload as any).scroll ? true : false;
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const loadPayload = async () => {
    setIsLoading(true);
    try {
      const data = await NftController.getPaginated({
        page: 1,
        size: (props.slot?.config.payload as any).limit,
        sort: JSON.stringify((props.slot?.config.payload as any).sort),
        query: JSON.stringify((props.slot?.config.payload as any).query),
      });

      setNfts(data.data);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadPayload();
  }, []);

  if (!isLoading && !nfts?.length) return <></>;

  return (
    <>
      <div
        className={`container mb-3 ${
          props.isLightTheme ? "text-black" : "text-white"
        }`}
      >
        {props.slot?.title && props.slot.title !== "" && (
          <div className="d-flex flex-wrap align-items-end justify-content-between">
            <p className="fs-h2 medium m-0">
              {t(props.slot.title ?? "")}
              {props.isNew && (
                <>
                  <span> - </span>
                  <span className="h4 light">{t("ds.new_everyday")}</span>
                </>
              )}
            </p>
            {location.pathname === "/" && (
              <p
                className={`cursor-pointer underline ${
                  isSmallScreen ? "mt-2" : ""
                }`}
                onClick={() => {
                  fireTagManagerEvent("open_secondary_market", {
                    source: "home",
                  });
                  navigate(getLink(LINK_TYPE.SECONDARY_MARKET));
                }}
              >
                {t("ds.show_all")}
              </p>
            )}
          </div>
        )}
        {/* <p>{t(props.slot?.subtitle ?? "")}</p> */}
      </div>
      <div className="containerScroll">
        <TakCarousel isLoading={isLoading} scroll={false} nfts={nfts} />
      </div>
    </>
  );
}
