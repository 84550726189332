import { COLLECTION_TYPE } from "../../../assets/models/iCollection";
import { NFT_SELL_STATUS } from "../../../assets/models/iNft";
import StayRequest from "../../../assets/TakyonDesignSystem/components/StayRequest/StayRequest";
import TakContainer from "../../../assets/TakyonDesignSystem/components/TakContainer/TakContainer";
import { iWallSlot, WALL_SLOT_TYPE } from "../../../config/wall";
import Search from "./Search";
import Promo from "../../../assets/TakyonDesignSystem/components/Promo/Promo";
import promoBG from "../../../assets/img/wall/promo.jpg";
import promoMobileBG from "../../../assets/img/wall/promo_mobile.jpg";
import promoLogo from "../../../assets/img/wall/promo_logo.png";
import promoBG2 from "../../../assets/img/wall/promo2.jpg";
import promoLogo2 from "../../../assets/img/wall/promo2_logo.jpg";
import promoBG3 from "../../../assets/img/wall/promo3.jpg";
import promoLogo3 from "../../../assets/img/wall/promo3_logo.svg";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import VeneziaImg from "../../../assets/img/directsearch/venezia.jpg";
import FirenzeImg from "../../../assets/img/directsearch/firenze.jpg";
import RomaImg from "../../../assets/img/directsearch/roma.jpg";
import MilanoImg from "../../../assets/img/directsearch/milano.jpg";
import AssisiImg from "../../../assets/img/directsearch/assisi.jpg";
import MateraImg from "../../../assets/img/directsearch/matera.jpg";
import SienaImg from "../../../assets/img/directsearch/siena.jpg";
import VeronaImg from "../../../assets/img/directsearch/verona.jpg";
import heroLeft from "../../../assets/img/directsearch/hero_left_min.png";
import heroRight from "../../../assets/img/directsearch/hero_right_min.png";
import heroMobile from "../../../assets/img/directsearch/hero_mobile_min.png";
import DiscoverCity, {
  DiscoverCityProps,
} from "../../../assets/TakyonDesignSystem/components/DiscoverCity/DiscoverCity";
import ScrollContainer from "../../../assets/TakyonDesignSystem/components/ScrollContainer/ScrollContainer";
import DiscoverHotel from "../../../assets/TakyonDesignSystem/DiscoverHotel/DiscoverHotel";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { useEffect, useState } from "react";
import { openModal } from "../../../assets/Utils/modal";
import TalksAnimation from "./TalksAnimation";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import bgImage from "../../../assets/img/directsearch/home.jpg";
import { Render } from "./utils";

export default function Metasearch() {
  const { t } = useTranslation();
  const isShortScreen = useMediaQuery({
    query: "only screen and (min-width : 769px) and (max-height : 600px)",
  });
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const [isMobileMinified, setIsMobileMinified] = useState(true);
  const isExperienceTak = {
    "payload.type": COLLECTION_TYPE.experience,
  };
  const mixedTakgetInspired = {
    $or: [
      {
        $and: [
          {
            $or: [
              { "payload.type": { $exists: false } },
              { "payload.type": "hotel" },
              { "payload.type": null },
            ],
          },
          {
            $or: [
              { isHotelFakeSupply: { $exists: false } },
              { isHotelFakeSupply: false },
            ],
          },
        ],
      },
      {
        $and: [
          {
            $or: [
              { "payload.type": { $exists: false } },
              { "payload.type": "hotel" },
              { "payload.type": null },
            ],
          },
          {
            isHotelFakeSupply: true,
          },
        ],
      },
      {
        ...isExperienceTak,
        sellStatus: NFT_SELL_STATUS.FOR_SALE,
      },
    ],
    sellStatus: "FOR_SALE",
  };

  const promo: iWallSlot = {
    title: "",
    config: {
      type: WALL_SLOT_TYPE.PROMO,
      payload: {
        title: "ds.promo_title",
        subtitle: "",
        background: isSmallScreen ? promoMobileBG : promoBG,
        logo: promoLogo,
        action: "https://www.clubdelsole.com/it",
      },
    },
  };
  const promo2: iWallSlot = {
    title: "",
    config: {
      type: WALL_SLOT_TYPE.PROMO,
      payload: {
        title: "ds.promo_title2",
        subtitle: "",
        background: promoBG2,
        logo: promoLogo2,
        action: "https://vretreats.com/cervino/",
      },
    },
  };
  const promo3: iWallSlot = {
    title: "",
    config: {
      type: WALL_SLOT_TYPE.PROMO,
      payload: {
        title: "ds.promo_title3",
        subtitle: "",
        background: promoBG3,
        logo: promoLogo3,
        action: "https://www.xalphotel.it/",
      },
    },
  };
  const stays: iWallSlot = {
    title: "secondary.stays_title",
    config: {
      type: WALL_SLOT_TYPE.CLASSIC,
      payload: {
        scroll: true,
        query: mixedTakgetInspired,
        sort: { isHotelFakeSupply: 1 },
        limit: 15,
      },
    },
  };
  const stayRequest: iWallSlot = {
    title: "secondary.stay_request_title",
    subtitle: "secondary.experiences_btn",
    config: {
      type: WALL_SLOT_TYPE.STAY_REQUEST,
      payload: {},
    },
  };
  const experiences: iWallSlot = {
    title: "secondary.experiences_title",
    config: {
      type: WALL_SLOT_TYPE.CLASSIC,
      payload: {
        scroll: true,
        query: {
          ...isExperienceTak,
          sellStatus: NFT_SELL_STATUS.FOR_SALE,
        },
        sort: { lockDate: -1 },
        limit: 4,
      },
    },
  };

  const mostWanted: DiscoverCityProps[] = [
    {
      title: t("ds.most_wanted_venezia"),
      action: "Venezia",
      image: VeneziaImg,
    },
    {
      title: t("ds.most_wanted_firenze"),
      action: "Firenze",
      image: FirenzeImg,
    },
    {
      title: t("ds.most_wanted_roma"),
      action: "Roma",
      image: RomaImg,
    },
    {
      title: t("ds.most_wanted_milano"),
      action: "Milano",
      image: MilanoImg,
    },
  ];
  const discoverCity: DiscoverCityProps[] = [
    {
      title: t("ds.most_wanted_matera"),
      action: "Matera",
      image: MateraImg,
    },
    {
      title: t("ds.most_wanted_verona"),
      action: "Verona",
      image: VeronaImg,
    },
    {
      title: t("ds.most_wanted_assisi"),
      action: "Assisi",
      image: AssisiImg,
    },
    {
      title: t("ds.most_wanted_siena"),
      action: "Siena",
      image: SienaImg,
    },
  ];

  const bestHotels: string[] = [
    "93608bb8-ddb1-4bfb-8089-245e7452dce1",
    "16827a11-a70d-4177-8fa1-95df4e2d822a",
    "c7e9c2e7-6b15-4594-ba0f-f9f0749d24fc",
    "8d1757b0-b030-443f-93e4-0360b59794fa",
    "f96e0a7b-0d4a-4cb3-8b90-1f2db178152e",
  ];

  const weekendHotels: string[] = [
    "d6964098-1214-4c50-a01b-d7092b5177e1",
    "b94820e2-c41b-4d19-931f-b39a4a7f7fb3",
    "bc73785a-f38a-44a0-b57f-0c1cf24e386a",
    "683cebad-d7a8-4b50-bd3d-3f409a0c296d",
  ];

  useEffect(() => {
    if (isSmallScreen && !isMobileMinified) {
      document.querySelector("html")!.style.overflow = "hidden";
    } else {
      document.querySelector("html")!.style.overflowY = "scroll";
    }
    return () => {
      document.querySelector("html")!.style.overflowY = "scroll";
    };
  }, [isMobileMinified]);

  return (
    <div
      className="w100 h100"
      style={{
        minHeight: "100dvh",
        marginTop: isShortScreen ? "44px" : "65px",
      }}
    >
      <div
        className="d-flex flex-column flex-md-row justify-content-center bg-white"
        style={{
          // height: `calc(100dvh - ${isShortScreen ? "42" : "63"}px)`,
          height: "100dvh",
        }}
      >
        <img
          src={bgImage}
          width="100%"
          style={{ height: "100dvh", position: "absolute", top: 0, left: 0 }}
        />
        <div
          className="position-absolute w100 bg-black"
          style={{ top: 0, left: 0, zIndex: 0, opacity: 0.8, height: "100dvh" }}
        ></div>
        {/* <img className="only-mobile" src={heroMobile} width="100%" /> */}
        {/* <img className="only-desktop" src={heroLeft} width="232px" /> */}
        <div
          className="px-1 d-flex flex-column align-items-center justify-content-center"
          style={{ maxWidth: "943px", zIndex: 1 }}
        >
          <div className="container">
            <div
              className="d-flex flex-column white-color regular"
              style={{ paddingTop: isSmallScreen ? "40px" : "93px" }}
            >
              <p
                className={`text-md-center ${
                  isSmallScreen ? "fs-h2" : "fs-h1-lg"
                }`}
              >
                {t("ds.title")}
              </p>
              <p className="text-md-center mt-4">
                <Render
                  text={t("ds.subtitle")}
                  props={{
                    className: `black-color bg-primary px-2 medium  ${
                      isSmallScreen ? "fs-body" : "fs-body-lg"
                    }`,
                  }}
                />
              </p>
            </div>
            {/* <div
            className="d-flex flex-wrap justify-content-md-center gap-2"
            style={{
              marginTop: "32px",
            }}
          >
            <div className="d-flex justify-content-center align-items-center border rounded-pill px-2 py-1 gap-1">
              <Icon icon="shoppingmode" size={24} color="white" />
              <p className="fs-body-xs white-color">{t("ds.hero-tag1")}</p>
            </div>
            <div className="d-flex justify-content-center align-items-center border rounded-pill px-2 py-1 gap-1">
              <Icon icon="published_with_changes" size={24} color="white" />
              <p className="fs-body-xs white-color">{t("ds.hero-tag2")}</p>
            </div>
            <div className="d-flex justify-content-center align-items-center border rounded-pill px-2 py-1 gap-1">
              <Icon icon="stars" size={24} color="white" />
              <p className="fs-body-xs white-color">{t("ds.hero-tag3")}</p>
            </div>
          </div> */}
            {/* Talk Animation */}
            <div style={{ marginBottom: isSmallScreen ? "36px" : "100px" }}>
              {/* <TalksAnimation /> */}
            </div>
            <div className="content" style={{ paddingBottom: "93px" }}>
              {/* <div className="only-mobile">
              <Button
                icon="search"
                className="w100"
                text={t("ds.searchMobile")}
                onClick={() => {
                  fireTagManagerEvent("open_search");
                  setIsMobileMinified(false);
                  openModal({
                    content: (
                      <>
                        <div className="modal-body">
                          <Search mode={isSmallScreen ? "mobile" : "desktop"} />
                        </div>
                      </>
                    ),
                  });
                }}
              />
            </div> */}
              {/* {isSmallScreen && !isMobileMinified && (
              <>
                <div
                  className="w100 p-2"
                  style={{
                    position: "fixed",
                    zIndex: 1000,
                    top: "70px",
                    left: 0,
                  }}
                >
                  <Search mode={isSmallScreen ? "mobile" : "desktop"} />
                </div>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 999,
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsMobileMinified(true);
                  }}
                ></div>
              </>
            )} */}
              <Search mode={isSmallScreen ? "mobile" : "desktop"} />
              {/* <p className="mt-3 white-color fs-body">
            {t("ds.seconday_market1")}{" "}
            <span>
              <a
                onClick={() => {
                  fireTagManagerEvent("open_secondary_market", {
                    source: "home",
                  });
                  navigate(getLink(LINK_TYPE.SECONDARY_MARKET));
                }}
                className="underline white-color cursor-pointer fs-body"
              >
                {t("ds.seconday_market2")}
              </a>
            </span>
          </p> */}
            </div>
          </div>
        </div>
        {/* <img className="only-desktop" src={heroRight} width="232px" /> */}
      </div>
      <div className="bg-white">
        <div className="d-flex flex-column">
          <div style={{ marginTop: "60px" }}></div>
          <div className="container">
            <p className="fs-h2 medium mb-3">{t("ds.most_wanted")}</p>
          </div>
          <div className="containerScroll" style={{ marginBottom: "40px" }}>
            <ScrollContainer
              isLightTheme={true}
              content={
                <>
                  <div className="d-flex gap-3 mx-3 mx-md-0">
                    {mostWanted.map((city, index) => (
                      <DiscoverCity
                        key={index}
                        title={city.title}
                        action={city.action}
                        image={city.image}
                      />
                    ))}
                  </div>
                </>
              }
            />
          </div>
          <div className="container">
            <Promo slot={promo}></Promo>
          </div>
          <div className="bg-black">
            <div style={{ marginTop: "40px", marginBottom: "30px" }}>
              <TakContainer slot={stays} isLightTheme={false} />
            </div>
            <div
              className="container"
              style={{ marginTop: "40px", marginBottom: "40px" }}
            >
              <StayRequest slot={stayRequest} />
            </div>
          </div>
          <div className="container" style={{ marginTop: "60px" }}>
            <p className="fs-h2 medium mb-3">{t("ds.best_city_mountain")}</p>
          </div>
          <div className="containerScroll">
            <DiscoverHotel hotelIdxs={bestHotels}></DiscoverHotel>
          </div>
          <div className="container" style={{ marginTop: "60px" }}>
            <p className="fs-h2 medium mb-3">{t("ds.discover_city")}</p>
          </div>
          <div className="containerScroll">
            <ScrollContainer
              isLightTheme={true}
              content={
                <>
                  <div className="d-flex gap-3 mx-3 mx-md-0">
                    {discoverCity.map((city, index) => (
                      <DiscoverCity
                        key={index}
                        title={city.title}
                        action={city.action}
                        image={city.image}
                      />
                    ))}
                  </div>
                </>
              }
            />
          </div>
          <div className="container" style={{ marginTop: "60px" }}>
            <p className="fs-h2 medium mb-3">{t("ds.discovercity")}</p>
          </div>
          <div className="containerScroll">
            <DiscoverHotel hotelIdxs={weekendHotels}></DiscoverHotel>
          </div>
          <div style={{ marginTop: "80px" }}></div>
        </div>
      </div>
    </div>
  );
}
