import { useState } from "react";
import IconNew from "../Icon/Icon";

export default function ClipboardButton({
  code,
  content,
}: {
  code: string;
  content?: JSX.Element;
}) {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <div
      className={`d-flex bg-black rounded-2 text-white px-3 py-2 mt-3 color-white cursor-pointer justify-content-between align-items-center ${
        isCopied ? "bg-black" : "bg-black"
      }`}
      onClick={() => {
        setIsCopied(true);
        navigator.clipboard.writeText(code);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      }}
    >
      {content ? content : <p>{code}</p>}
      {isCopied ? (
        <IconNew
          icon="check_check"
          hasFill={false}
          color={"white"}
          size={16}
          className="ml-2"
        />
      ) : (
        <IconNew
          icon="copy"
          hasFill={false}
          color={"white"}
          size={16}
          className="ml-2"
        />
      )}
    </div>
  );
}
