import { useEffect, useState } from "react";
import Icon from "../Icon/Icon";

interface Props {
  stars: number | string;
  className?: string;
  isFlex?: boolean;
  margin?: string;
}

export default function Stars({
  stars,
  className,
  isFlex = true,
  margin = "",
}: Props) {
  const [amount, setAmount] = useState(1);

  useEffect(() => {
    try {
      if (!isNaN(Number(stars))) {
        setAmount(Number(stars));
        return;
      }

      if (typeof stars === "string") {
        const a = Number(stars[0]);
        if (!isNaN(a)) {
          setAmount(a);
          return;
        }

        const b = stars.length;
        setAmount(b);
      }
    } catch (error) {
      console.log("Error setting stars:", stars);
    }
  }, [stars]);

  if (isNaN(amount) || amount === 0) return <></>;

  return (
    <div
      className={`${isFlex ? "d-flex" : "d-inline"} ${
        margin ? `${margin}` : ""
      }`}
    >
      {Array.from(Array(Math.floor(amount)).keys()).map((key) => {
        return (
          <Icon
            style={{ margin: "0 -3px" }}
            key={key}
            fill
            className={className ? className : `gold-color`}
            type="symbol"
            size={18}
            icon="star"
          />
        );
      })}
    </div>
  );
}
