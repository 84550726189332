import { useCallback, useEffect, useMemo, useState } from "react";
import { Hotel } from "../../../assets/Services/metasearch";
import {
  Map,
  AdvancedMarker,
  APIProvider,
  MapControl,
  useMap,
  CollisionBehavior,
} from "@vis.gl/react-google-maps";
import { calculateAverageInArray } from "./utils";
import { getSearchRadius } from "../../../assets/Utils/geo";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import IconNew from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";

interface HotelsMapProps {
  hotels: Hotel[];
  selected: string;
  onClick(hotelId: string): any;
  searchLocation?(coords: google.maps.LatLngLiteral, radius: number): void;
  hotelPrice?: number;
  isLoading?: boolean;
}

export default function HotelsMap({
  hotels,
  selected,
  searchLocation,
  onClick,
  hotelPrice,
  isLoading,
}: HotelsMapProps) {
  const [urlParams] = useSearchParams();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  // Set the initial center to the average hotel coordinates
  const searchCenter = useMemo<google.maps.LatLngLiteral>(
    () => ({
      lat: calculateAverageInArray(hotels.map((h) => h.property.latitude)),
      lng: calculateAverageInArray(hotels.map((h) => h.property.longitude)),
    }),
    [hotels],
  );

  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral | null>(
    searchCenter,
  );

  useEffect(() => {
    setMapCenter(searchCenter);
  }, [searchCenter]);

  const radius = useMemo(() => {
    const urlRadius = urlParams.get("radius");
    if (urlRadius) return Number(urlRadius);

    const latitudes = hotels.map((h) => h.property.latitude);
    const longitudes = hotels.map((h) => h.property.longitude);

    const latMin = Math.min(...latitudes);
    const latMax = Math.max(...latitudes);
    const lngMin = Math.min(...longitudes);
    const lngMax = Math.max(...longitudes);

    return getSearchRadius(latMin, latMax, lngMin, lngMax);
  }, [hotels, urlParams]);

  const defaultZoom = useMemo(() => getZoomFromRadius(radius), [radius]);

  const renderedPins = useMemo(
    () =>
      hotels.map((hotel, i) => {
        let directPrice =
          hotel?.property?.priceBreakdown?.grossPrice?.value ?? 0;
        if (hotel?._collection?.preData?.deltaPriceBookingCom)
          directPrice =
            (hotel?.property?.priceBreakdown?.grossPrice?.value ?? 0) /
            (1 + hotel._collection.preData.deltaPriceBookingCom);

        if (hotelPrice) {
          directPrice = hotelPrice;
        }

        return (
          <CustomPin
            onClick={() => onClick(String(hotel.hotel_id))}
            key={hotel.hotel_id}
            lat={hotel.property.latitude}
            lng={hotel.property.longitude}
            text={`€${directPrice.toFixed(0)}`}
            selected={String(hotel.hotel_id) === selected}
            required={!!hotel._collection && !hotel._collection.isFree}
            index={i}
          />
        );
      }),
    [hotels, selected],
  );

  return (
    <APIProvider apiKey={String(process.env.REACT_APP_GOOGLE_API_KEY)}>
      {isLoading ? (
        <div className="w100 h100 d-flex justify-content-center align-items-center">
          <Spinner
            animation="border"
            role="status"
            style={{ width: "4rem", height: "4rem", color: "#e0e0e0" }}
          ></Spinner>
        </div>
      ) : (
        <>
          {isSmallScreen && (
            <div
              className="position-absolute bg-white rounded-circle"
              style={{ top: "10px", right: "10px", zIndex: 1000 }}
            >
              <IconNew icon="close" fill={false} hasFill color="black" />
            </div>
          )}
          <Map
            className="w100 h100"
            center={mapCenter}
            onCenterChanged={(e) => setMapCenter(e.detail.center)}
            defaultZoom={defaultZoom}
            mapId="RESULTS_MAP"
            fullscreenControl={false}
            mapTypeControl={false}
            streetViewControl={false}
          >
            <>
              {renderedPins}
              {!!searchLocation && (
                <MapControl position={2}>
                  <ReloadButton
                    search={searchLocation}
                    searchCenter={searchCenter}
                  />
                </MapControl>
              )}
            </>
          </Map>
        </>
      )}
    </APIProvider>
  );
}

interface PinProps {
  lat: number;
  lng: number;
  text: string;
  index: number;
  required: boolean;
  selected: boolean;

  onClick(): any;
}

function CustomPin({
  lat,
  lng,
  text,
  selected,
  onClick,
  index,
  required,
}: PinProps) {
  const [isHovered, setIsHovered] = useState(false);
  const map = useMap();

  return (
    <AdvancedMarker
      position={{ lat, lng }}
      onClick={() => {
        map?.setCenter({ lat, lng });
        onClick();
      }}
      zIndex={selected || isHovered ? 1 : -1 * index}
      collisionBehavior={
        required
          ? CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL
          : CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY
      }
    >
      <div
        className={`custom-pin ${selected ? "selected" : ""}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={`pin-box ${selected ? "selected" : ""}`}>{text}</div>
      </div>
    </AdvancedMarker>
  );
}

function ReloadButton(props: {
  searchCenter: google.maps.LatLngLiteral;
  search(coordinates: google.maps.LatLngLiteral, radius: number): any;
}) {
  const map = useMap();

  useEffect(() => {
    let debounceTimer: NodeJS.Timeout;
    let firstTime = true;

    if (map) {
      const listener = map.addListener("bounds_changed", () => {
        clearTimeout(debounceTimer);
        if (!firstTime) {
          debounceTimer = setTimeout(() => {
            // search();
          }, 1000);
        } else {
          firstTime = false;
        }
      });

      return () => {
        clearTimeout(debounceTimer);
        listener.remove();
      };
    }
  }, [map]);

  const search = useCallback(() => {
    let radius = 10;
    let center = map?.getCenter()?.toJSON() || props.searchCenter;
    if (map) {
      const zoom = map.getZoom();
      radius = getRadiusFromZoom(zoom);
    }

    return props.search(center, radius);
  }, [map]);

  return (
    <></>
    //  (
    //   <Button
    //     style={{ marginTop: 40 }}
    //     onClick={search}
    //     text="Search in this area"
    //   />
    // )
  );
}

function getZoomFromRadius(radius: number) {
  if (radius < 1) return 15;
  if (radius < 5) return 14;
  if (radius < 10) return 13;
  if (radius < 20) return 12;
  if (radius < 50) return 11;
  if (radius < 100) return 10;
  if (radius < 200) return 9;
  if (radius < 500) return 8;
  if (radius < 1000) return 7;
  return 6;
}

function getRadiusFromZoom(zoom: number | null | undefined) {
  if (!zoom || zoom >= 12) return 10;
  if (zoom === 11) return 20;
  if (zoom === 10) return 50;
  if (zoom === 9) return 100;
  if (zoom === 8) return 200;
  if (zoom === 7) return 500;
  if (zoom === 6) return 1000;
  return 2000;
}
