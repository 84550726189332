import { Hotel } from "../Services/metasearch";
import { hotelCensus } from "./iNft";

export enum COLLECTION_TYPE {
  experience = "experience",
  hotel = "hotel",
}

type hotelStats = {
  averageBookingPrice?: number;
  averageNumberOfDaysPerStay?: number;
  averageOccupancyPercentage?: number;
  directBookingPercentage?: number;
  nonRefoundableSalesPercentage?: number;
};

export type publicContact = {
  type: "phone" | "website" | "whatsapp" | "email";
  value: string;
};

export default interface iCollection {
  _id: string;
  averagePrice: number;
  name: string;
  type?: COLLECTION_TYPE;
  census: hotelCensus;
  censusStats: hotelStats;
  images: Array<string>;

  isFree: boolean | null;

  priorityIndex?: number;

  publicContacts?: publicContact[];

  preData?: {
    bookingEngineUrlRegex?: string;
    conversionRate?: number;
    otaFee?: number;

    bookingComId?: string;
    bestRateOnOtaType?: "refundable" | "non-refundable";
    bookingEngine: string;
    deltaPriceBookingCom: number;
    deltaPriceHotelsCom: number;
    advantagesOnDirectWebsite: string[];
  };
  isLoyaltyEnabled?: boolean;
  loyatyDiscountPercentage?: number;
  loyatyCPAPerNight?: number;
  _bookingComData?: Hotel;
}
