import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/img/icons/TakyonDark.svg";
import { useSearchParams } from "react-router-dom";
import { getCheckoutLink } from "../../../assets/Services/metasearch";
import { createUrlWithUtm } from "../../../assets/Utils/link";
import { Render } from "./utils";

export default function MetasearchRedirect() {
  const [urlParams] = useSearchParams();
  const [url, setUrl] = useState("");
  const hasPromo = urlParams.get("hasPromo") === "true";

  const { t } = useTranslation();

  useEffect(() => {
    // Convert URLSearchParams to a key-value object
    const paramsObject = Object.fromEntries(urlParams.entries());
    const { collectionId, fallbackUrl, ...searchParameters } = paramsObject;

    let url = createUrlWithUtm(fallbackUrl);
    setUrl(url);

    (async () => {
      if (collectionId) {
        try {
          const res = await getCheckoutLink({
            collectionId,
            searchParameters,
          });
          if (res) {
            url = createUrlWithUtm(res);
            setUrl(url);
          }
        } catch (e) {
          console.error(e);
        }
      }

      setTimeout(() => {
        window.location.href = url;
      }, 3000);
    })();
  }, [urlParams]);

  return (
    <div
      style={{
        background: "white",
        height: "100svh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        className="spinner-border mb-4"
        role="status"
        style={{ width: "39px", height: "39px", borderWidth: "0.15rem" }}
      >
        <span className="visually-hidden">{t("ds.loading")}</span>
      </div>
      <img style={{ height: "40px" }} src={logo} alt="" />
      <br />
      <br />
      <div className="p-3" style={{ maxWidth: "700px" }}>
        <p className="text-center fs-h3">{t("ds.redirect_title")}</p>
        <div className="height-12"></div>
        {hasPromo && (
          <p className="text-center fs-body-lg medium dark-grey-color">
            <Render
              text={t("ds.redirect_subtitle")}
              props={{
                className: `black-color bg-primary px-2 medium`,
              }}
            />
          </p>
        )}
        <div className="height-25"></div>
        <p className="text-center fs-body dark-grey-color light">
          {t("ds.redirect_cta1")} <a href={url}>{t("ds.redirect_cta2")}</a>
        </p>
      </div>
    </div>
  );
}
