import axios from "axios";
import iCollection from "../models/iCollection";
import { iPagination, iPaginationProps } from "../models/iPagination";

export default class CollectionController {
  static async clickOnCollection(
    collectionId: string,
    metadata?: any,
  ): Promise<any> {
    //console.log("clickOnCollection data: ", collectionId, metadata);
    const response = await axios.post(`/collectionclick`, {
      collectionId,
      metadata,
    });
    console.log("clickOnCollection response: ", response.data);
    return response.data;
  }
  static async getPaginated(
    params: iPaginationProps,
  ): Promise<iPagination<iCollection>> {
    const response = await axios.get("/collection/paginate-public", {
      params,
    });
    return response.data;
  }

  static async patchCollection(payload: any): Promise<iCollection> {
    const response = await axios.patch("/collection", payload);
    return response.data;
  }
}
