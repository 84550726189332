export function getSearchRadius(
  latMin: number,
  latMax: number,
  lngMin: number,
  lngMax: number,
): number {
  const R = 6371; // Radius of the Earth in km

  const dLat = (latMax - latMin) * (Math.PI / 180);
  const dLng = (lngMax - lngMin) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(latMin * (Math.PI / 180)) *
      Math.cos(latMax * (Math.PI / 180)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);

  const c = Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in km
}
