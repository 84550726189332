import React from "react";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import { useOfferNavigation } from "../../../../hooks/hooks";
import BookingHeader from "../../../../../assets/TakyonDesignSystem/components/Wallet/sub/BookingHeader";
import helpers from "../../../../helpers/helpers";

interface CommonLayoutProps {
  title: string;
  children?: React.ReactNode; // Aggiunta della prop children per accettare elementi figli
}

const _OffersActionsLayout: React.FC<CommonLayoutProps> = ({
  title,
  children,
}) => {
  const { t } = useTranslation();
  const { offer, goBack } = useOfferNavigation();
  const nft = offer._nft!;

  if (!nft) return <></>;

  return (
    <div className="m-4">
      {/* Layout comune */}
      <div>
        <Button
          variant="blank"
          icon="arrow_back"
          onClick={goBack} // Assicurati che `goBack` sia gestito correttamente
          text="Indietro"
        />
      </div>
      <h3 className="h3 mt-1 mb-3 medium">{title}</h3>
      <div className="mt-4 border-bottom">
        <BookingHeader nft={nft} />
        <div className="height-32"></div>

        <div className="h4 medium d-flex gap-1">
          <div>{t("wallet.bookings.forsale")}:</div>
          <div>{helpers.price(nft.currentPrice)}</div>
        </div>
      </div>
      {/* Fine del layout comune */}

      {/* Renderizzazione dei children */}
      {children}
    </div>
  );
};

export default _OffersActionsLayout;
