import { useTranslation } from "react-i18next";
import IconNew from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import {
  BookingComSearchRegions,
  Destination,
  SearchParameters,
} from "../../../assets/Services/metasearch";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { closeModal, openModal } from "../../../assets/Utils/modal";
import { DateRangePicker, Range } from "react-date-range";
import { useCallback, useEffect, useRef, useState } from "react";
import { addDays } from "date-fns";
import { dateToSearchFormat } from "./utils";
import {
  datetimeRangeToString,
  datetimeToString,
  SMALL_SCREEN,
} from "../../../assets/Utils/generic";

import React from "react";
import { debounce } from "lodash";
import { useMediaQuery } from "react-responsive";
import { getLocaleLanguage } from "../../../assets/Services/i18next";
import { LANG } from "../../../config/Lang";
import Skeleton from "react-loading-skeleton";
import { it, es, enUS } from "date-fns/locale";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import FiltersModal, { updateCategoryFilter } from "./FiltersModal";
import FilterTags from "./FilterTags";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { motion, AnimatePresence } from "framer-motion";

interface CircularButtonProps {
  onClick?: () => void;
  children?: React.ReactNode; // Custom content (e.g., icons, text)
  className?: string; // Allow additional styles
}

const CircularButton: React.FC<CircularButtonProps> = ({
  onClick,
  children,
  className = "",
}) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center cursor-pointer white-color black-background rounded-circle ${className}`}
      style={{ width: "25px", height: "25px", userSelect: "none" }} // Adjust size here
      onClick={onClick}
    >
      {children || <span>+</span>}
    </div>
  );
};

export const MIN_PRICE = 10;
export const MAX_PRICE = 1000;

export const search = (
  params: SearchParameters,
  navigate: Function,
  reset?: boolean,
  triggerTagManagerEvent?: boolean,
) => {
  let _params = { ...params };

  if (reset) {
    _params = {
      ..._params,
      categories_filter: undefined,
      price_min: undefined,
      price_max: undefined,
      sort_by: undefined,
    };
  }

  const isLocationSearched =
    _params.dest_id || (_params.latitude && _params.longitude);

  if (!isLocationSearched || !_params.departure_date || !_params.arrival_date)
    return;

  if (!_params.categories_filter?.includes("property_type::")) {
    [
      "property_type::204", // hotel
      "property_type::203", // hostel
      "property_type::205", // motel
      "property_type::208", // b&b
      "property_type::225", // capsule hotels
      "property_type::206", // resort
      "property_type::221", // lodges
      "property_type::226", // love hotels
    ].forEach((type) => {
      _params = updateCategoryFilter(type, type, _params, true);
    });
  }

  if (_params.price_min && Number(_params.price_min) <= MIN_PRICE)
    _params.price_min = undefined;

  if (_params.price_max && Number(_params.price_max) >= MAX_PRICE)
    _params.price_max = undefined;

  const queryParamsFiltered = Object.entries(_params)
    .filter(([_, value]) => value)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const queryString = new URLSearchParams(queryParamsFiltered).toString();
  const target = "/directsearch/search?" + queryString;

  const childrens = params.children_age?.split(",") || [];
  if (triggerTagManagerEvent) {
    fireTagManagerEvent("search_started", {
      destination: params.dest_label,
      dates: params.departure_date + " - " + params.arrival_date,
      adults: params.adults ?? 2,
      children: childrens.length,
      type: params.search_type,
      source: window.location.pathname.includes("/directsearch")
        ? "directsearch"
        : "home",
    });
  }
  closeModal();

  navigate(target);
};

const DestinationInput: React.FC<{
  setParams: React.Dispatch<React.SetStateAction<SearchParameters>>;
  params: SearchParameters;
  mode: "mobile" | "desktop";
}> = ({ setParams, params, mode }) => {
  const { t } = useTranslation();

  const [isFocused, setIsFocused] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [shouldOpenOver, setShouldOpenOver] = useState(false);
  const [locations, setLocations] = useState<Destination[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const locaitonRef = useRef<HTMLDivElement>(null);

  const searchLocation = async (query: string) => {
    setIsLoading(true);
    try {
      const data = await BookingComSearchRegions({
        query,
      });
      setLocations(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const searchCallbackDebounced = useRef(debounce(searchLocation, 1000));

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        locaitonRef.current &&
        !locaitonRef.current.contains(event.target as Node)
      ) {
        setIsLocationOpen(false);
      }
    }

    if (isLocationOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isLocationOpen]);

  const handleScroll = () => {
    const input = document.getElementById("search-location-input");
    const navH = document.querySelector("nav")?.clientHeight ?? 0;
    if (input) {
      const rect = input.getBoundingClientRect();
      // 400 px is the height of the dates calendar, used as referecne for the location dropdown since its height is dynamic
      setShouldOpenOver(
        rect.bottom + 400 > window.innerHeight && rect.top - 400 > navH,
      );
    }
  };

  const destinationInput = () => {
    return (
      <>
        <input
          autoComplete="off"
          id="search-location-input"
          style={{
            border: "unset",
            margin: "0",
            padding: "0",
            outline: "none", // Remove focus border
            background: "transparent", // Optional: Ensure no background styles
            color: "black", // Ensures input text inherits the parent's color
            fontSize: mode === "mobile" ? "16px !important" : "",
          }}
          placeholder={t("ds.destination_ph")}
          value={params?.dest_label}
          className={`${
            mode === "desktop" ? "fs-body-xs" : "fs-body"
          } medium text-nowrap`}
          type="custom"
          onChange={(e) => {
            setParams({ ...params, dest_label: e.target.value });
            if (e.target.value.length >= 2) {
              setIsLocationOpen(true);
              setIsLoading(true);
              searchCallbackDebounced.current(e.target.value);
            }
          }}
          onFocus={() => setIsFocused(true)} // Set focused state
          onBlur={() => setIsFocused(false)} // Reset focused state
        />
      </>
    );
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className="d-flex"
      style={{ padding: "10px 20px", position: "relative" }}
    >
      <div
        id="search-location-input"
        className="h100 w100 d-flex gap-2 cursor-pointer"
        onClick={() => {
          setIsLocationOpen(!isLocationOpen);
          setParams({ ...params, dest_label: "" });
          setLocations([]);

          document.getElementById("search-location-input")?.focus();
        }}
      >
        {/* Mobile */}
        <div className="d-flex gap-2 w100 d-md-none">
          <IconNew icon="location_on" style={{ fill: "lightgray" }} />
          {destinationInput()}
        </div>
        {/* Desktop */}
        <div className="d-flex gap-2 d-none d-md-flex">
          <div>
            <IconNew icon="location_on" style={{ fill: "lightgray" }} />
          </div>
          <div className="d-flex flex-column w100">
            <span
              className={`${
                mode === "desktop" ? "fs-body-xs" : "fs-body"
              } light dark-grey-color`}
            >
              {t("ds.destination")}
              {destinationInput()}
            </span>
          </div>
        </div>
      </div>

      {isLocationOpen && (locations.length > 0 || isLoading) ? (
        <>
          <div
            ref={locaitonRef} // Add ref here
            className="rounded"
            style={{
              position: "absolute",
              ...(shouldOpenOver && mode === "desktop"
                ? { bottom: "105%" }
                : { top: "105%" }),
              zIndex: 100,
              overflow: "hidden",
              border: "1px solid lightgray",
              boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
              background: "white",

              left: mode === "mobile" ? 0 : "unset",
              right: mode === "mobile" ? 0 : "unset",
            }}
          >
            {isLoading ? (
              <div
                style={{ width: "300px" }}
                className="d-flex gap-2 flex-column p-3"
              >
                <Skeleton width={100} />
                <Skeleton />
                <br />
                <Skeleton width={100} />
                <Skeleton />
                <br />
                <Skeleton width={100} />
                <Skeleton />
                <br />
              </div>
            ) : (
              <>
                <div
                  style={{ minWidth: "300px" }}
                  className="d-flex gap-2 flex-column p-3"
                >
                  {locations.map((l, key) => {
                    return (
                      <div
                        onClick={() => {
                          setParams({
                            ...params,
                            dest_label: l.label,
                            dest_id: l.dest_id,
                            search_type: l.dest_type,
                            latitude: "",
                            longitude: "",
                          });

                          setIsLocationOpen(false);
                        }}
                        key={key}
                        className="cursor-pointer d-flex gap-2"
                      >
                        <div className="mt-1">
                          <IconNew icon="location_on" />
                        </div>
                        <div>
                          <p
                            className={`${
                              mode === "desktop" ? "fs-body-sm" : "fs-body"
                            } medium`}
                          >
                            {l.city_name ? l.city_name : l.label}
                          </p>
                          <p
                            className={`${
                              mode === "desktop" ? "fs-body-sm" : "fs-body"
                            } light`}
                          >
                            {l.label}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

const DatesInput: React.FC<{
  setParams: React.Dispatch<React.SetStateAction<SearchParameters>>;
  params: SearchParameters;
  mode: "mobile" | "desktop";
  isSmallScreen: boolean;
}> = ({ setParams, params, mode, isSmallScreen }) => {
  const { t } = useTranslation();

  const [shouldOpenOver, setShouldOpenOver] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [shouldOpenCalendar, setShouldOpenCalendar] = useState(!params.dest_id);

  const [range, setRange] = useState<Range>({
    startDate: params?.arrival_date
      ? new Date(params?.arrival_date)
      : new Date(),
    endDate: params?.departure_date
      ? new Date(params?.departure_date)
      : addDays(new Date(), 1),
    key: "selection",
  });

  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldOpenCalendar && params.dest_id) {
      setShouldOpenCalendar(false);
      setIsCalendarOpen(true);
    }
  }, [params.dest_id, shouldOpenCalendar]);

  const handleScroll = () => {
    const input = document.getElementById("datesInput");
    const navH = document.querySelector("nav")?.clientHeight ?? 0;
    if (input) {
      const rect = input.getBoundingClientRect();
      setShouldOpenOver(
        rect.bottom + 400 > window.innerHeight && rect.top - 400 > navH,
      );
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Handle clicks outside the calendar
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setIsCalendarOpen(false);
      }
    }

    if (isCalendarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCalendarOpen]);

  const [calendarLocale, setCalendarLocale] = useState<Locale>();

  useEffect(() => {
    const initAsync = async () => {
      const l = await getLocaleLanguage();

      let langValue: LANG | undefined = l as LANG | undefined;

      let calendarLocale = enUS;
      if (langValue === LANG.it) calendarLocale = it;
      if (langValue === LANG.es) calendarLocale = es;
      setCalendarLocale(calendarLocale);

      if (langValue === LANG.en) langValue = "en-gb" as any;

      setParams({ ...params, languagecode: langValue });
    };
    initAsync();
  }, [t]);

  return (
    <div
      className="w100"
      style={{ padding: "10px 20px", position: "relative" }}
    >
      <div
        id="datesInput"
        onClick={() => setIsCalendarOpen(!isCalendarOpen)}
        className="d-flex gap-2 cursor-pointer h100"
      >
        {/* Mobile */}
        <div className="d-flex gap-2 w100 d-md-none">
          <IconNew icon="calendar_month" style={{ fill: "lightgray" }} />
          <p className={`fs-body medium text-nowrap`}>
            {params.arrival_date
              ? datetimeToString(
                  params.arrival_date,
                  undefined,
                  true,
                  false,
                  false,
                  false,
                  true,
                ) +
                " - " +
                datetimeToString(
                  params.departure_date,
                  undefined,
                  true,
                  false,
                  false,
                  false,
                  true,
                )
              : t("ds.checkin_ph")}
          </p>
        </div>
        {/* Desktop */}
        <div className="gap-2 w100 d-none d-md-flex">
          <div className="d-flex gap-2 w100 h100">
            <div>
              <IconNew icon="calendar_month" style={{ fill: "lightgray" }} />
            </div>
            <div className="d-flex flex-column">
              <span
                className={`${
                  mode === "desktop" ? "fs-body-xs" : "fs-body"
                } light dark-grey-color`}
              >
                {t("ds.checkin")}
              </span>
              <span
                style={{
                  fontSize: mode === "mobile" ? "16px !important" : "",
                }}
                className={`${
                  mode === "desktop" ? "fs-body-xs" : "fs-body"
                } medium text-nowrap`}
              >
                {params.arrival_date
                  ? datetimeToString(
                      params.arrival_date,
                      undefined,
                      isSmallScreen,
                    )
                  : t("ds.checkin_ph")}
              </span>
            </div>
          </div>

          <div
            className="vertical-divider m-0"
            style={{ height: "unset" }}
          ></div>

          <div className="d-flex gap-2 w100 h100">
            <div>
              <IconNew icon="calendar_month" style={{ fill: "lightgray" }} />
            </div>
            <div className="d-flex flex-column">
              <span
                className={`${
                  mode === "desktop" ? "fs-body-xs" : "fs-body"
                } light dark-grey-color`}
              >
                {t("ds.checkout")}
              </span>
              <span
                style={{
                  fontSize: mode === "mobile" ? "16px !important" : "",
                }}
                className={`${
                  mode === "desktop" ? "fs-body-xs" : "fs-body"
                } medium text-nowrap`}
              >
                {params.departure_date
                  ? datetimeToString(
                      params.departure_date,
                      undefined,
                      isSmallScreen,
                    )
                  : t("ds.checkout_ph")}
              </span>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isCalendarOpen && (
          <motion.div
            ref={calendarRef} // Add ref here
            className={`flex flex-column h100${
              mode === "desktop" ? " rounded" : ""
            }`}
            style={
              mode === "desktop"
                ? {
                    position: "absolute",
                    ...(shouldOpenOver ? { bottom: "105%" } : { top: "105%" }),
                    zIndex: 100,
                    overflow: "hidden",
                    border: "1px solid lightgray",
                    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
                    background: "white",
                    minHeight: "400px",
                    minWidth: "550px",
                  }
                : {
                    position: "fixed",
                    top: "0px",
                    left: 0,
                    right: 0,
                    zIndex: 200,
                    overflow: "hidden",
                    border: "1px solid lightgray",
                    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
                    background: "white",
                    height: "100dvh",
                  }
            }
            initial={
              mode === "desktop" ? { opacity: 0 } : { y: 100, opacity: 0 }
            }
            animate={mode === "desktop" ? { opacity: 1 } : { y: 0, opacity: 1 }}
            exit={
              mode === "desktop"
                ? { y: -20, opacity: 0 }
                : { y: 100, opacity: 0 }
            }
            transition={{ duration: 0.2 }}
          >
            {mode === "mobile" && (
              <div className="d-flex justify-content-between p-3 align-items-center">
                <div className="fs-h4 medium">{t("ds.choose_dates")}</div>
                <IconNew
                  icon="close"
                  onClick={() => setIsCalendarOpen(false)}
                ></IconNew>
              </div>
            )}
            <div style={{ maxHeight: "78vh", overflowY: "auto" }}>
              <DateRangePicker
                preventSnapRefocus
                locale={calendarLocale}
                minDate={new Date()}
                className="rounded w100"
                onChange={(item) => {
                  setRange(item.selection);

                  setParams({
                    ...params,
                    arrival_date: item.selection.startDate
                      ? dateToSearchFormat(item.selection.startDate)
                      : undefined,
                    departure_date: item.selection.endDate
                      ? dateToSearchFormat(item.selection.endDate)
                      : undefined,
                  });
                  if (
                    item.selection.startDate !== item.selection.endDate &&
                    mode === "desktop"
                  ) {
                    setIsCalendarOpen(false);
                  }
                }}
                months={2}
                ranges={[range]}
                direction={mode === "desktop" ? "horizontal" : "vertical"}
                staticRanges={[]}
                inputRanges={[]}
              />
              {mode === "mobile" && (
                <div
                  className="w100 p-3"
                  style={{ position: "fixed", bottom: 0 }}
                >
                  <Button
                    onClick={() => setIsCalendarOpen(false)}
                    className="w100"
                    text={t("ds.done")}
                  />
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

/**
 * Component to input and manage guest details for a search form.
 *
 * Props:
 * - setParams: Function to update search parameters.
 * - params: Current search parameters.
 * - mode: The display mode, either "mobile" or "desktop".
 *
 * Displays the number of adults and children, with options to increase or decrease the count.
 * Adapts its display based on the 'mode' prop.
 */
const GuestsInput: React.FC<{
  setParams: React.Dispatch<React.SetStateAction<SearchParameters>>;
  params: SearchParameters;
  mode: "mobile" | "desktop";
}> = ({ setParams, params, mode }) => {
  const { t } = useTranslation();
  const [adults, setAdults] = useState(Number(params.adults ?? 2));
  const [shouldOpenOver, setShouldOpenOver] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);

  const guestsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        guestsRef.current &&
        !guestsRef.current.contains(event.target as Node)
      ) {
        setIsGuestsOpen(false);
      }
    }

    if (isGuestsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isGuestsOpen]);

  useEffect(() => {
    setParams((p) => ({ ...p, adults: adults.toString() }));
  }, [adults, setParams]);

  const [children, setChildren] = useState<number[]>(
    params.children_age?.split(",").map((a) => Number(a)) ?? [],
  );
  useEffect(() => {
    setParams((p) => ({ ...p, children_age: children.join(",") }));
  }, [children, setParams]);

  const handleScroll = () => {
    const input = document.getElementById("guests-input");
    const navH = document.querySelector("nav")?.clientHeight ?? 0;
    if (input) {
      const rect = input.getBoundingClientRect();
      // 100 px is an indicative height for guests input
      setShouldOpenOver(
        rect.bottom + 100 > window.innerHeight && rect.top - 100 > navH,
      );
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div style={{ padding: "10px 20px", position: "relative" }}>
      <div
        id="guests-input"
        className="cursor-pointer h100 w100"
        onClick={() => setIsGuestsOpen(!isGuestsOpen)}
      >
        {/* Mobile */}
        <div className="d-flex gap-2 w100 d-md-none">
          <IconNew icon="people" style={{ fill: "lightgray" }} />
          <span
            style={{
              fontSize: mode === "mobile" ? "16px !important" : "",
            }}
            className={`${
              mode === "desktop" ? "fs-body-xs" : "fs-body"
            } medium text-nowrap text-truncate`}
          >
            {adults ?? t("ds.guests_ph")} {t("ds.adults")}{" "}
            {children.length > 0 && (
              <>
                - {children.length} {t("ds.childs")}
              </>
            )}
          </span>
        </div>
        {/* Desktop */}
        <div className="gap-2 w100 d-none d-md-flex">
          <div>
            <IconNew icon="people" style={{ fill: "lightgray" }} />
          </div>
          <div className="d-flex flex-column">
            <span
              className={`${
                mode === "desktop" ? "fs-body-xs" : "fs-body"
              } light dark-grey-color`}
            >
              {t("ds.guests")}
            </span>
            <span
              style={{
                fontSize: mode === "mobile" ? "16px !important" : "",
              }}
              className={`${
                mode === "desktop" ? "fs-body-xs" : "fs-body"
              } medium text-nowrap text-truncate`}
            >
              {adults ?? t("ds.guests_ph")} {t("ds.adults")}{" "}
              {children.length > 0 && (
                <>
                  - {children.length} {t("ds.childs")}
                </>
              )}
            </span>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isGuestsOpen && (
          <motion.div
            ref={guestsRef} // Add ref here
            className={mode === "desktop" ? "rounded p-3" : "p-3"}
            style={
              mode === "desktop"
                ? {
                    position: "absolute",
                    ...(shouldOpenOver ? { bottom: "105%" } : { top: "105%" }),
                    zIndex: 100,
                    overflow: "hidden",
                    border: "1px solid lightgray",
                    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
                    background: "white",
                  }
                : {
                    position: "fixed",
                    top: "0px",
                    left: 0,
                    right: 0,
                    zIndex: 200,
                    overflow: "hidden",
                    border: "1px solid lightgray",
                    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
                    background: "white",
                    height: "100dvh",
                  }
            }
            initial={
              mode === "desktop" ? { opacity: 0 } : { y: 100, opacity: 0 }
            }
            animate={mode === "desktop" ? { opacity: 1 } : { y: 0, opacity: 1 }}
            exit={
              mode === "desktop"
                ? { y: -20, opacity: 0 }
                : { y: 100, opacity: 0 }
            }
            transition={{ duration: 0.2 }}
          >
            {mode === "mobile" && (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="fs-h4 medium">{t("ds.choose_guests")}</div>
                  <IconNew
                    icon="close"
                    onClick={() => setIsGuestsOpen(false)}
                  ></IconNew>
                </div>
                <div className="height-20"></div>
              </>
            )}
            <div
              style={{ width: mode === "desktop" ? "300px" : "unset" }}
              className="d-flex flex-column gap-3 w100"
            >
              <div className="w100 d-flex justify-content-between align-items-center">
                <span className="fs-body medium">{t("ds.guests_adults")}</span>

                <div className="d-flex align-items-center">
                  <CircularButton
                    onClick={() => {
                      setAdults((a) => (a > 1 ? a - 1 : 1));
                    }}
                  >
                    -
                  </CircularButton>
                  <span className="width-40 text-center fs-body medium">
                    {adults}
                  </span>
                  <CircularButton
                    onClick={() => {
                      setAdults((a) => a + 1);
                    }}
                  >
                    +
                  </CircularButton>
                </div>
              </div>
              <div className="w100 d-flex justify-content-between align-items-center">
                <span className="fs-body medium">{t("ds.guests_kids")}</span>

                <div className="d-flex align-items-center">
                  <CircularButton
                    onClick={() => {
                      setChildren((c) => c.slice(0, -1));
                    }}
                  >
                    -
                  </CircularButton>
                  <span className="width-40 text-center fs-body-lg medium">
                    {children.length}
                  </span>
                  <CircularButton
                    onClick={() => {
                      setChildren((c) => [...c, 10]);
                    }}
                  >
                    +
                  </CircularButton>
                </div>
              </div>
            </div>

            {mode === "mobile" && <div className="height-25"></div>}

            {mode === "mobile" && (
              <div className="w100 py-3">
                <Button
                  onClick={() => setIsGuestsOpen(false)}
                  className="w100"
                  text={t("ds.done")}
                />
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const MobileMinified: React.FC<{
  params: SearchParameters;
  showFilters: boolean;
  setIsMobileMinified?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ params, showFilters, setIsMobileMinified }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div id="searchbar" className="d-flex flex-column gap-2">
      <div
        className="d-flex gap-2"
        onClick={() => {
          fireTagManagerEvent("open_search");
          setIsMobileMinified?.((v) => !v);
        }}
      >
        <div
          style={{
            borderRadius: "8px",
            boxShadow: "4px 3px 10px 1px rgba(0, 0, 0, 0.05)",
            userSelect: "none",
            border: "1px solid lightgray",
            maxWidth: "calc(100% - 48px)",
          }}
          className="w100 d-flex align-items-center gap-2 p-3 py-2 cursor-pointer white-background"
        >
          <IconNew icon="search" />
          <span className="fs-body-sm medium text-truncate">
            {params?.dest_label?.split(",")[0] ?? ""}
            {", "}
            {datetimeRangeToString(
              params?.arrival_date,
              params?.departure_date,
              -new Date().getTimezoneOffset(),
              false,
            )}
            , {params?.adults ?? 2} {t("ds.guests")}
          </span>
        </div>

        {showFilters && (
          <Button
            size="sm"
            variant="light"
            icon="menu"
            onClick={(e) => {
              e.stopPropagation();
              openModal({
                content: (
                  <FiltersModal
                    searchParameters={params}
                    onFilter={(f) => {
                      search({ ...params, ...f }, navigate, undefined, true);
                      closeModal();
                    }}
                  />
                ),
              });
            }}
          />
        )}
      </div>
      <FilterTags params={params} />
    </div>
  );
};

const MobileVersion: React.FC<{
  setParams: React.Dispatch<React.SetStateAction<SearchParameters>>;
  params: SearchParameters;
  isSmallScreen: boolean;
  setIsMobileMinified?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setParams, params, isSmallScreen, setIsMobileMinified }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div
        id="mobile-searchbar"
        style={{
          ...(window.location.pathname.includes("/directsearch")
            ? { position: "fixed", zIndex: 10, width: "90%" }
            : {}),
        }}
        className="d-flex flex-column p-3 gap-2 white-background rounded-3"
      >
        <div className="border-bottom">
          <DestinationInput
            setParams={setParams}
            params={params}
            mode="mobile"
          />
        </div>
        <div className="border-bottom">
          <DatesInput
            setParams={setParams}
            params={params}
            mode="mobile"
            isSmallScreen={isSmallScreen}
          />
        </div>
        <GuestsInput setParams={setParams} params={params} mode="mobile" />
        <Button
          onClick={() => {
            search(params, navigate, undefined, true);
            setIsMobileMinified?.(true);
          }}
          text={t("ds.search")}
        />
      </div>
    </>
  );
};

const DesktopVersion: React.FC<{
  setParams: React.Dispatch<React.SetStateAction<SearchParameters>>;
  params: SearchParameters;
  isSmallScreen: boolean;
  showFilters: boolean;
}> = ({ setParams, params, isSmallScreen, showFilters }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openFiltersModal = useCallback(() => {
    fireTagManagerEvent("open_filter");
    openModal({
      content: (
        <FiltersModal
          searchParameters={params}
          onFilter={(f) => {
            search({ ...params, ...f }, navigate);
            closeModal();
          }}
        />
      ),
    });
  }, [params, search]);

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex align-items-center justify-content-center w100 gap-2">
        <div
          style={{
            border: "1px solid lightgray",
            boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
          }}
          className="w100 rounded-5 white-background d-flex"
        >
          {/* location */}
          <DestinationInput
            setParams={setParams}
            params={params}
            mode="desktop"
          />
          <div
            className="vertical-divider m-0"
            style={{ height: "unset" }}
          ></div>
          {/* dates */}
          <DatesInput
            setParams={setParams}
            params={params}
            mode="desktop"
            isSmallScreen={isSmallScreen}
          />
          <div
            className="vertical-divider m-0"
            style={{ height: "unset" }}
          ></div>
          {/* guests */}
          <GuestsInput setParams={setParams} params={params} mode="desktop" />
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ padding: "10px" }}
          >
            <Button
              className="rounded-5"
              textClass="fs-body-sm medium"
              onClick={() => {
                search(params, navigate, true, true);
              }}
              text={t("ds.search")}
            />
          </div>
        </div>
        {showFilters && (
          <Button
            textClass="fs-body-sm medium"
            text={t("ds.filters")}
            variant="light"
            icon="menu"
            onClick={openFiltersModal}
          />
        )}
      </div>
      <FilterTags params={params} />
    </div>
  );
};

export default function Search({
  showFilters,
  searchParameters,
  mode = "desktop",
  isMobileMinified,
  setIsMobileMinified,
}: {
  showFilters?: boolean;
  searchParameters?: SearchParameters;
  mode?: "mobile" | "desktop";
  isMobileMinified?: boolean;
  setIsMobileMinified?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const [params, setParams] = useState<SearchParameters>({
    ...searchParameters,
    departure_date: searchParameters?.departure_date || "2025-05-01",
    arrival_date: searchParameters?.arrival_date || "2025-04-29",
  });
  useEffect(() => {
    if (searchParameters) setParams(searchParameters);
  }, [searchParameters]);

  if (mode === "mobile") {
    if (isMobileMinified) {
      return (
        <MobileMinified
          params={params}
          showFilters={!!showFilters}
          setIsMobileMinified={setIsMobileMinified}
        />
      );
    }
    return (
      <MobileVersion
        setIsMobileMinified={setIsMobileMinified}
        params={params}
        setParams={setParams}
        isSmallScreen={isSmallScreen}
      />
    );
  }

  return (
    <DesktopVersion
      params={params}
      setParams={setParams}
      isSmallScreen={isSmallScreen}
      showFilters={!!showFilters}
    />
  );
}
